.modal {
  &-dialog {
    width: calc(100% - 30px);
    margin: 0 auto;
  }

  &-coins {
    max-width: 193px;
  }
&-link{
  font-size: 8px !important;
}
  &-border {
    border: 1px dashed $light;
    border-radius: 1.6rem;
    padding: 10px;
    margin-right: -5px;
    margin-left: -5px;
  }

  .error_message {
    font-size: 1.2rem;
    margin-top: 0.8rem;
    text-align: center;
  }

  .react-datepicker-wrapper {
    width: 100%;
  }

  &-wrapper {
    max-width: 300px;
    right: 8px;

    h4 {
      color: $primary;
    }
  }
  .within-custom{
    font-size: 13px !important;

    @media(max-width:767.68px){
      font-size: 12px !important;
    }
  }
p{
  font-size: 12px;
  font-weight: 400;
}
  h6 {
    font-size: 1.2rem;
  }
h3{
  font-size: 2.2rem;
}

  &-content {
    
    background: linear-gradient(180deg, #5d2322 0%, #341616 100%);
    border-radius: 1.5rem;
    padding: 3.2rem;
    background-size: cover;

    .victory-wrapper {
      position: relative;
      transform: none;
      left: 0;
      top: 0;

      .img-coins {
        padding-top: 0 !important;
      }

      h2 {
        font-size: 24px;
        margin: 0 60px;
      }

      h5 {
        font-size: 1.2rem;
        line-height: 1.6rem;
      }

      &-step {
        padding-bottom: 15px;

        &::before,
        &::after {
          border-color: $dark;
        }
      }

      .btn {
        background-color: $secondary;
        height: 40px;
      }

      .row {
        margin-bottom: 0 !important;
      }

      @media (max-width: 1200px) {
        max-width: 100%;
      }

      @media (max-width: 767.76px) {
        padding: 0;

        .btn {
          height: 22px;
          padding: 1rem 2rem;
          font-size: 8px;
          white-space: nowrap;
          border-radius: 4px;
        }

        h2 {
          font-size: 14px;
          line-height: 1.8rem;
          margin: 0 27px;
        }

        h5 {
          font-size: 0.7rem;
          line-height: 0.9rem;
        }

        .col {
          padding-left: 0;
          padding-right: 0;
        }
      }
    }
  }

  &-header {
    border: none;
    padding: 0;
  }

  &-xl {
    max-width: 805px;
  }

  &-lg {
    max-width: 666px;
  }

  &-md {
    max-width: 390px;
  }

  &-img {
    max-width: 193px;
  }

  &-sm {
    max-width: 590px;
  }

  &-title {
    font-size: $modal-title;
  }

  &-left-img {
    max-height: 198px;
  }

  &-register-img {
    max-width: 120px;
    width: 100%;
  }

  .error_message {
    text-align: center;
  }

  .backArrow {
    top: -43px;
    left: 260px;
  }

  &-wallet {
    .modal-dialog {
      max-width: 390px;
      width: 100%;

      .display-1 {
        font-size: 2.2rem;
        line-height: 2.6rem;
      }
      h4{
        font-size: 1.4rem;
        line-height: 1.8rem;
        margin-bottom: 11px;
      }

      .card {
        background-color: $danger;
        max-width: 137px;
        width: 100%;
        border-radius: 12px;

        &-body {
          border-bottom: 1px solid #cb4250;
          padding: 9px 35px;

          img {
            max-width: 114px;
            min-width: 66px;
            width: 100%;
          }
         
        }

        &-title {
          font-size: 1.2rem;
          padding: 7px !important;
          margin-bottom: 0;
          line-height: 1.6rem;
        }
      }

      .btn {
        height: 50px;
        font-size: 1.6rem;
        &:disabled{
          background-color: #F60400;
          color: #fff !important;
        }
      }

      .enableButton {
        opacity: 0.4 !important;
      }

      .enableTicketCard {
        opacity: 0.5 !important;
      }
    }

    @media (max-width: 767.76px) {
      .modal-dialog {
        .btn {
          height: 50px;
          line-height: 1rem;
          padding-left: 0;
          padding-right: 0;
          font-size: 1.2rem;
        }

        .card {
          max-width: 137px;

          &-body {
            padding: 5px;

            .card {
              max-width: 120px;
              margin: 0 auto;
            }

            img {
              max-width: 61px;
            }
          }

          &-title {
            font-size: 1.2rem;
          }
        }
      }
    }
  }

  h4 {
    line-height: 2.5rem;
    font-weight: 700;
  }

  &-sub-title {
    font-size: $modal-sub-title;
  }

  .close {
    position: absolute;
    right: 30px;
    top: 25px;
    z-index: 1;
    opacity: 1;

    span {
      display: none;
    }

    &::before {
      content: '';
      background: url('~/public/assets/images/icons/close.png');
      background-size: contain;
      background-repeat: no-repeat;
      height: 16px;
      width: 16px;
      display: block;
      bottom: 6px;
      left: 9px;
      position: relative;
    }
  }

  .claim-link {
    font-size: 1rem;
  }

  a {
    color: $light;
    cursor: pointer;
    font-size: 1.4rem;

    &.text-underline {
      text-decoration: underline;
    }

    &.resend-link {
      font-size: 1.2rem;
      text-decoration: underline;
    }
  }

  .form {
    &-label {
      font-size: 1.6rem;
      line-height: 2rem;
    }

    &-condition {
      font-size: 1.4rem;
    }

    &-group {
      position: relative;
    }

    &-venue-field {
      button {
        background: $light;
        color: #a49d9d;
        font-size: 14px;
        width: 100%;
        appearance: none;
        text-align: left;
        margin-bottom: 20px;
        position: relative;

        @media (max-width: 575.98px) {
          padding: 0 9px 0 20px;
          font-size: 1rem;
        }

        @media (max-width: 365.98px) {
          padding-right: 20px;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        &.dropdown-toggle::after {
          background: url('~/public/assets/images/icons/bd-dropdown.svg');
          background-size: contain;
          background-repeat: no-repeat;
          height: 17px;
          width: 16px;
          display: block;
          position: absolute;
          right: 23px;
          top: 21px;
          content: '';
          border: none;

          @media (max-width: 365.98px) {
            height: 13px;
            width: 12px;
            right: 11px;
          }
        }

        &:hover,
        &:focus,
        &:active .show,
        .dropdown-toggle {
          background: $light;
          color: #a49d9d;
          box-shadow: none;
        }
      }

      .dropdown {
        &-menu {
          width: 100%;
          border-radius: 1.2rem;
          margin-top: 1rem !important;

          @media (max-width: 767.9px) {
            min-width: unset !important;
          }

          @media (max-width: 335.9px) {
            overflow-x: scroll;
          }
        }

        &-item {
          padding: 1rem 1.5rem;
          display: flex;
          align-items: center;

          &.active,
          &:active {
            background-color: transparent;
          }

          .close-icon {
            display: inline-block;
            margin-left: auto;
            font-size: 2rem;
            line-height: 1.6rem;
          }

          input {
            margin-right: 1rem;
            accent-color: $primary;
          }
        }
      }
    }

    &-input {
      font-size: 1.1rem;
      padding: 2.1rem 2rem;
      height: 50px;
      display: block;
      border-radius: 1.2rem;
      width: 100%;
      border-color: transparent;
    }

    &-check-label {
      margin-left: 18px;
    }

    &-control {
      font-size: 1.5rem;
      color: $dark;
      padding: 2.1rem 1.5rem;
      border-radius: 1.2rem;
      height: 55px;

      width: 100%;

      &::placeholder {
        opacity: 0.3;
      }
    }
  }

  .btn {
    border-radius: 10rem;
    padding: 1.5rem;
    height: 55px;
  }

  .btn-share {
    height: 33px;
    font-size: 1.2rem;
    padding: 0.8rem;
    top: unset;
    position: relative;
  }
}

@media (min-width: 767.98px) and (max-width: 1200px) {
  .modal {
    h4 {
      font-size: 2rem;
    }
  }
}

@media (max-width: 766.98px) {
  .modal {
    &-dialog {
      width: calc(100% - 15px);
    }

    &-content {
      padding: 1.5rem 2.9rem 3rem 2.9rem;
    }

    &-coins {
      max-width: 149px;
    }

    .error_message {
      font-size: 0.9rem;
    }

    &-title {
      font-size: 1.8rem !important;
      line-height: 2.4rem;
    }

    &-border {
      padding: 13px 9px;
    }

    &-text {
      font-size: 1.4rem !important;
    }

    &-wrapper {
      max-width: 204px;
      right: 0px;
    }

    &-sub-title {
      font-size: 1.4rem;
      line-height: 1.8rem;
    }

    &-left-img {
      max-height: 197px;
    }

    &-refer-trophy {
      max-height: 151px;
    }

    h3 {
      font-size: 1.8rem;
      line-height: 2rem;
    }

    h4 {
      font-size: 1.2rem !important;
    }

    .display-1 {
      font-size: 1.8rem ;
    }

    p{
      font-size: 12px !important;
      font-weight: 400 !important;
    }

    h6 {
      font-size: 1.2rem !important;
      line-height: 1.4rem;
    }

    .backArrow {
      top: -41px;
      left: 116px;

      @media (max-width: 506.98px) {
        left: 176px;
      }

      @media (max-width: 428.98px) {
        left: 210px;
      }

      @media (max-width: 405.98px) {
        left: 154px;
      }

      img {
        width: 14px !important;
      }
    }

    a.h5 {
      font-size: 1.1rem;
    }

    .form {
      &-label {
        font-size: 1.4rem;
        line-height: 1.8rem;
      }

      &-refer-label {
        font-size: 1.1rem;
      }

      &-link {
        font-size: 1rem;
      }

      &-control {
        font-size: 1.1rem;
        padding: 2.1rem 2rem;
        height: 50px;
        width: 100%;
      }

      &-condition {
        font-size: 1rem !important;

        a {
          font-size: 1rem;
        }
      }

      &-avatar {
        width: 48px;
      }
    }

    .btn {
      font-size: 1.6rem;
      padding: 1.2rem 4.4rem;
      height: 50px;
      text-wrap: nowrap;
      display: flex;
      justify-content: center;
      align-items: center;

      
    }
  }
}

@media (max-width: 476.98px) {
  .modal {
    &-img {
      max-width: 40vw;
    }

    &-content {
      padding: 1.5rem 2.3rem 2.4rem 2.3rem;
    }
  }
}

.select-wrapper {
  position: relative;

  .input-select {
    appearance: none;
    padding: 0.2rem 1.5rem;
    position: relative;
  }

  &::after {
    background: url('~/public/assets/images/icons/bd-dropdown.svg');
    background-size: contain;
    background-repeat: no-repeat;
    height: 17px;
    width: 16px;
    display: block;
    position: absolute;
    right: 23px;
    top: 21px;
    content: '';

    @media (max-width: 365.98px) {
      height: 13px;
      width: 12px;
      right: 11px;
    }
  }

  &.division {
    &::after {
      top: 46px;
    }
  }

  .form-select {
    appearance: none;
  }
}

//dropdown css
.dropdown-container {
  border-radius: 1.2rem !important;
  height: 50px !important;
  padding: 4px 10px;

  &:focus-within {
    box-shadow: none !important;
    border: none !important;
  }

  .dropdown-heading {
    font-size: 1.5rem;
    color: #000000;

    svg {
      color: $primary !important;
    }
  }
}

.dropdown-heading-value {
  color: $dark;
}

.dropdown-content {
  color: $primary;
}

.dropdown-heading svg {
  display: none;
}

.btn-code {
  color: $danger !important;
  font-size: 3.2rem;
}

.form-check-input.is-invalid~.form-check-label {
  color: $light !important;
}

// For safari webkit input
@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) {
    input:focus {
      outline: none;
      -webkit-tap-highlight-color: transparent;
    }
  }
}

@media (max-width: 405px) {
  .otp-no {
    display: block;
  }
}

// custom checkbox
.form-check-input,
.dropdown-item input[type='checkbox'] {
  display: none;

  &:checked+.form-check-label::before {
    background-image: url('~/public/assets/images/icons/checked.png');
  }
}

.form-check-label {
  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 5px;
    width: 13px;
    height: 13px;
    background: url('~/public/assets/images/icons/check.png') no-repeat center center;
    background-size: cover;
  }
}

.form-venue-field {
  .dropdown-item {
    position: relative;
    padding-left: 25px !important;

    @media (max-width: 767.9px) {
      padding-left: 10px !important;
      font-size: 9px;
    }

    .form-check-label {
      &::before {
        top: 14px;
        left: 13px;
        background: url('~/public/assets/images/icons/check.png') no-repeat center center;
        background-size: cover;
        border: 1px solid $primary;
        border-radius: 4px;

        @media (max-width: 767.9px) {
          top: 11px;
          left: 7px;
        }
      }
    }

    input[type='checkbox']:checked+.form-check-label::before {
      background-image: url('~/public/assets/images/icons/checked-red.png');
    }
  }
}

.show>.btn-primary.dropdown-toggle,
.dropdown-toggle.btn-primary:not(:disabled):not(.disabled):active,
.dropdown-toggle.btn-primary:not(:disabled):not(.disabled):focus {
  background: $light;
  color: #a49d9d;
  box-shadow: none;
}