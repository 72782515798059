.footer {
  padding: 10px 0 40px;
  background: #000;
  .container-fluid {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  }
  &-left {
    order: 1;
    p {
      margin-bottom: 0;
      font-size: 1.1rem;
      text-transform: uppercase;
      img {
        vertical-align: middle;
        margin-top: -2px;
        margin-right: 5px;
      }
      a {
        font-size: 1rem;
        color: $light;
        text-transform: none;
        display: inline-block;
        margin-top: 1rem;
      }
    }
  }
  &-right {
    order: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    p {
      font-size: 1.2rem;
      margin: 0 0 1.5rem;
    }
  }
}

.social {
  &-list {
    display: flex;
    &:focus-visible {
      outline: none;
    }
    li {
      display: inline-block;
      margin: 0 4px;
      a {
        &:hover {
          text-decoration: none;
        }
      }
      img {
        max-width: 4.4rem;
      }
      .icon {
        font-size: 28px;
        line-height: 28px;
        background-color: $primary;
        border-radius: 50%;
        padding: 6px;
        text-align: center;
        &::before {
          color: $light;
        }
      }
    }
  }
}

@media (max-width: 945px) {
  .footer {
    &-left {
      padding: 2rem 0;
      .logo-item {
        margin: 0 0.5rem;
        width: 5.5rem;
      }
    }
    &-center {
      order: 3;
      margin-top: 2.5rem;
    }
  }

  .social {
    &-list {
      margin: 0;
      padding: 0;
      li {
        img {
          max-width: 3.8rem;
        }
      }
    }
  }
}
@media (max-width: 650px) {
  .footer {
    &-left {
      text-align: center;
      order: 2;
      p {
        a {
          margin-top: 0;
        }
      }
    }
    &-right {
      text-align: center;
      order: 1;
      padding-top: 2rem;
    }
    .container-fluid {
      flex-direction: column;
    }
  }
}
@media (max-width: 600px) {
  .footer {
    padding-bottom: 100px;
  }
  .grecaptcha-badge {
    bottom: 170px !important;
  }
}
@media (max-width: 575.98px) {
  .footer-center p {
    font-size: 1rem;
  }
}

@media (max-width: 450px) {
  .footer-center p {
    font-size: 0.8rem;
  }
}
