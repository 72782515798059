.how-win-card {
    .row {
        max-width: 1200px;
        width: 100%;
        margin: 0 auto;
    }

    .col {
        &::after {
            content: "";
            position: absolute;
            border-top: 2px dotted black;
            top: 50%;
            transform: translateY(-50%);
            left: calc(100% - 14px);
            height: 1px;
            width: 32px;
        }

        &:last-child {
            &::after {
                display: none;
            }
        }
    }

    .card {
        background-color: transparent;
        // max-width: 236px;
        width: 100%;
        height: 100%;
        border: 2px solid $light;
        border-radius: 1.5rem;
        position: relative;
        margin: 0 auto;


        &-img-wrapper {
            min-height: 201px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }

        .btn {
            font-size: 1.6rem;
            max-width: 137px;
            width: 100%;
            height: 36px;

        }

        &-img {
            display: flex;
            align-items: center;
            max-width: 140px;
            width: 100%;
            justify-content: center;

        }

        &-title {
            font-size: 1.6rem;

        }

        &-text {
            font-size: 1.5rem;
        }

    }

    @media (max-width:990.98px) {
        .row {
            max-width: 349px;
        }

        .col {
            padding: 0 2px;

            &::after {
                display: none;
            }
        }

        .card {
            &-body {
                padding: 12px 2px;
            }

            &-title {
                font-size: 7px;
                line-height: 10px;
            }

            &-text {
                font-size: 6px;
                line-height: 8px;
            }

            &-img-wrapper {
                min-height: 87px;
            }

            &-img {
                max-width: 60px;
                min-height: 22px;
            }

            .btn {
                font-size: 8px;
                max-width: 60px;
                padding: 3px 13px;
                height: 22px;
            }
        }
    }

    @media (max-width:767.67px) {
        .row {
            width: 100%;
            margin: 0 auto;

            h2 {
                font-size: 1.8rem;
            }
        }

        .card {
            max-width: 84px;
            border-radius: 1rem;
            border-width: 1px;

            .btn {
                font-size: 8px;
                max-width: 58px;
                text-wrap: nowrap;
                width: 100%;
            }

            &-img {
                display: flex;
                align-items: center;
                width: 100%;
                justify-content: center;

            }

            &-text {
                font-size: 6px;
                line-height: 1rem;

                @media (max-width:370.98px) {
                    font-size: 0.55rem;
                }
            }

        }
    }
}