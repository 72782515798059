//sidebar
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 0px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar {
  width: 8px;
  background-color: #f5f5f5;
}

body:has(.offcanvas-menu.navbar-collapse.collapse.show) {
  overflow: hidden;
}

::-webkit-scrollbar-thumb {
  border-radius: 0px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #333;
}

.offcanvas-menu {
  width: 350px;
  max-width: 80%;
  transition: transform 0.2s ease-in-out;
  transform: translateX(-100%);
  position: fixed;
  height: 100vh;
  // background: $danger;
  background: linear-gradient(180deg, #3b1717 0%, #4c1d1b 100%);
  left: 0;
  top: 83px;
  z-index: 16;
  padding-top: 41px;

  @media (max-height: 1000px) {
    overflow-y: auto;
    padding-bottom: 100px;
  }

  @media (max-width: 767.98px) {
    top: 94px;
    padding-top: 26px;
  }
  &.show {
    transform: translateX(0);
  }
}

.sidebar-main {
  .navbar {
    display: block;
    padding: 0;

    .navbar-toggler {
      padding: 0;
      margin-right: 60px;
      position: relative;

      &:focus-visible {
        outline: none;
      }

      &::after {
        content: '';
        width: 100%;
        height: 100%;
        left: 0;
        top: 82px;
        position: fixed;
        background: rgba(0, 0, 0, 0.7);
        z-index: 1;

        @media (max-width: 767.98px) {
          top: 93px;
        }
      }

      &.collapsed {
        &::after {
          display: none;
        }
      }

      @media (max-width: 767px) {
        margin-right: 10px;
      }
    }
  }
}

button.navbar-toggler {
  border: none;

  &.collapsed {
    .navbar-toggler-icon {
      background-image: url('~/public/assets/images/toggle.png');
    }
  }
}

.navbar-light .navbar-toggler-icon {
  width: 31px;
  height: 22px;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url('~/public/assets/images/close.png');
}

//Feature

.tu-sidebar-list-main {
  ul {
    margin-bottom: 22px;

    li {
      margin-bottom: 0.8rem;
      font-size: 1.3rem;
      background: none;
      border: none;
      padding: 0;
      list-style: none;
      cursor: pointer;

      .tu-side-icon {
        display: inline-block;
        width: 22px;
      }

      a {
        margin-left: 10px;
        vertical-align: middle;
        color: $light;
        text-decoration: none;
      }
    }

    li.last-list-item {
      a {
        line-height: 28px;

        span {
          display: block;
          margin-left: 40px;
          font-size: 18px;
        }
      }
    }
  }
}

.code-share {
  width: 15px;
}

//Buy Now
.tu-sidebar-buy-main {
  h2 {
    font-size: 14px;
    color: $light;
  }

  .brand-items {
    img {
      width: 100%;
      display: block;
      cursor: pointer;
    }

    a {
      margin-bottom: 11px;
      display: flex;
      align-items: center;
    }
  }
}

.tu-language-select {
  position: relative;

  &::after {
    position: absolute;
    content: '';
    background-image: url('~/public/assets/images/language-dropdown.svg');
    top: 8px;
    width: 13px;
    height: 10px;
    right: 0px;
    z-index: -1;
  }

  select {
    border: none;
    background: transparent;
    color: $light;
    font-size: 1.6rem !important;
    text-align: left;
    padding: 0px;
    appearance: none;
    width: 84px;
    -webkit-appearance: none;

    cursor: pointer;
    font-size: min(1rem, 3vw);

    &:focus,
    &:active {
      box-shadow: none !important;
      outline: none;
      border: none;
      background: none !important;
      color: inherit;
    }

    option {
      color: $dark;
    }
  }

  @media (max-width: 767.98px) {
    width: 58px;

    select {
      width: 55px;
    }
  }
}

.sidebar-main {
  .navbar {
    .tu-language-select {
      width: calc(100% - 22px);
      position: relative;

      &::after {
        position: absolute;
        content: '';
        background-image: url('~/public/assets/images/language-dropdown.svg');
        top: 5px;
        width: 13px;
        height: 10px;
        right: 15px;
        z-index: -1;
      }

      select {
        appearance: none !important;
        font-size: 1.4rem !important;
        width: 100% !important;

        @media (max-width: 767.98px) {
          font-size: 1.2rem !important;
        }
      }

      position: relative;
    }
  }
}

//Privacy policy
.tu-sidebar-privacy-main {
  .tu-language-select select {
    width: calc(100% - 140px);
    color: $light;
    background-color: transparent;
    margin-top: 22px;
    margin-left: -4px;
    font-weight: 400;
    font-size: 14px !important;

    option {
      color: $dark;
    }
  }

  &.tu-privacy {
    ul {
      a {
        margin-left: 0;
      }
    }
  }

  .tu-change-lang {
    margin-top: 22px;

    .dropdown-menu {
      top: 38px;
      width: calc(100% - 54px);

      .dropdown-item {
        font-size: 22px;
        padding: 3px 10px;
      }
    }
  }
}

.tu-privacy {
  ul {
    li {
      a {
        margin-left: 0;
      }
    }
  }

  .btn-sm {
    padding: 1.3rem 3rem;
    color: #cf152d !important;
  }
}

.list-bottom-border {
  position: relative;
  padding-bottom: 20px !important;

  &::after {
    position: absolute;
    content: '';
    border-bottom: 1 px solid $light;
    width: calc(100% - 22px);
    background-color: $light;
    height: 1px;
    margin-right: 22px;
    bottom: 0;
    left: 0;
  }
}

@media (max-width: 767.98px) {
  .tu-sidebar-list-main {
    ul {
      li {
        font-size: 1.2rem;
      }
    }
  }

  .tu-sidebar-buy-main {
    padding-left: 15px;

    h2 {
      font-size: 1.2rem;
    }

    .brand-items {
      grid-template-columns: 1fr 2.2fr;
    }
  }
}

.buy-now-link {
  img {
    max-width: 40px;
  }

  span {
    font-size: 1.4rem;
  }

  @media (max-width: 767.98px) {
    img {
      max-width: 36px;
    }

    span {
      font-size: 1.2rem;
    }
  }
}
