.winner-contest {
  padding-bottom: 2rem;
  background-size: cover;

  .paginationButton {
    height: 27px;
    font-size: 1rem;
    padding: 7px 27px;

    &:hover {
      color: $light;
    }
  }

  .list {
    padding-inline-start: 0px;

    &-wrapper {
      border: 2px solid $light;
      padding: 3.4vw 3.8vw 1.4vw 3.8vw;
      border-radius: 4rem;
      position: relative;
      z-index: 1;
      max-width: 852px;
      width: 100%;
      margin: 0 auto;
    }

    &-item {
      display: flex;
      justify-content: space-around;
      line-height: 3vw;
      border-radius: 1rem;
      padding: 1rem 2rem;
      margin-bottom: 2.4rem;

      h6 {
        margin: 0;
        font-size: 1.5rem;
        width: 100%;
        text-align: left;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;

      }

      &-1 {
        max-width: 101px;
      }

      &-2 {
        max-width: 101px;
      }

      &-3 {
        max-width: 400px;
        text-align: center;
      }

      &-4 {
        max-width: 140px;
      }

    }

    &-head {
      background-color: $primary;
    }

    &-avatar-wrapper {
      position: relative;
      max-width: 150px;
      width: 100%;
    }

    &-avatar {
      max-width: 50px;
      border-radius: 50%;
      position: absolute;
      left: 0;
      top: -18px;
      border: 3px solid $light;
      background-color: $light;
    }

    &-anchor {
      font-weight: 400;
      font-size: 24px;
      line-height: 27px;
      text-decoration: underline;
      text-align: center;
    }
  }

  .view-link {

    a,
    button {
      color: $light;
      font-size: 1.5rem;
      text-decoration: underline;
    }
  }

  @media (max-width: 767.67px) {

    padding-bottom: 1.3rem;
    background-size: cover;


    .winners-div {
      min-width: 95vw;
    }

    .paginationButton {
      height: 16px;
      font-size: 0.8rem;
      padding: 0px 19px;
    }

    .list {
      &-wrapper {
        padding: 7.2vw 3.7vw 5.8vw 3.7vw;
        border-radius: 2.8rem;
      }

      &-item {
        margin-bottom: 1.9rem;
        border-radius: 2vw;

        &-1 {
          max-width: 33px;
        }

        &-2 {
          max-width: 40px;
        }

        &-3 {
          max-width: 200px;
          text-align: center !important;
        }

        &-4 {
          max-width: 85px;
        }
      }

      &-avatar {
        max-width: 39px;
        top: -12px;
      }

      h6 {
        font-size: 1rem;
      }

    }

    .view-link a,
    .view-link button {
      font-size: 1rem;
    }
  }
}

.btn-link:disabled,
.btn-link.disabled {
  color: muted;
  pointer-events: none;
  opacity: 0.4 !important;
}

.btn-link {
  font-weight: 400;
  color: #ffff;
  text-decoration: none;
}