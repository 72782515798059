.bg {
  &-pattern {
    background-position: center top;
    background-size: cover;
    background-repeat: no-repeat;
    &-1{
  background-image: url("~/public/assets/images/bgs/bg-d-home-middle.svg");
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  padding-bottom: 118px;
  background-position: top;
  @media (min-width:1600px) {
    background-position: 0 20%;
  }
  &::after {
    content: "";
    background: linear-gradient(180deg, rgba(217, 217, 217, 0) 0%, rgba(0, 0, 0, 0.70) 99.04%);
    width: 100%;
    height: 253px;
    position: absolute;
    z-index: 0;
    bottom: 0;
    display: block;
  }
}
  &-2 {
      background: url("~/public/assets/images/hero-banner/contest-banner.svg") no-repeat center;
      background-size: cover;
      
    }
  

    &-6 {
      background-image: url("~/public/assets/images/bgs/contest-middile-bg.png");
      position: relative;
      background-size: contain;
      &::after {
        content: "";
        background: linear-gradient(180deg, rgba(217, 217, 217, 0) 0%, rgba(0, 0, 0, 0.70) 99.04%);
        width: 100%;
        height: 253px;
        position: absolute;
        z-index: 0;
        bottom: 0;
        display: block;
      }
    }
  }
}

@media (max-width: 767.98px) {
  .bg {
    &-pattern {
      &-main {
        background: url("~/public/assets/images/bgs/main-background.png") no-repeat center bottom;
        background-size: contain;
      }

      &-1{
        background-image: url("~/public/assets/images/bgs/home-middle-bg.svg") ;
padding-bottom: 0;
@media (min-width:400px) and (max-width:767px) {
  background-position: 0 20%;
}
    &::after{
      background: none;
    }
      
      }
      &-2{
        background-image: url("~/public/assets/images/hero-banner/m-contest-banner.svg");
        background-position: bottom;
      }
     
     
      &-6 {
        background-image: url("~/public/assets/images/bgs/contest-middile-bg.png");
        &::after{
          content: "";
   height: 88px;
 mix-blend-mode: multiply;
     }
     
      }
    }
  }
}