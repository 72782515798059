.hero {
  &-video {
    width: 100% !important;
    height: 100% !important;
    // height: 45vh !important;
    // height: 425px !important;
    // position: absolute;
    // left: 0;
    // right: 0;
    // top: 0;
  }
  &-slider {
    .slick-dots {
      bottom: 5%;
    }
  }
  &-banner {
    background: #000;
    padding: 0 40px;
    &-frame {
      margin-bottom: -5%;
      position: relative;
    }
  }
  &-inner {
    position: relative;
  }
  &-slider {
    position: absolute;
    top: 11.3%;
    left: 2.6%;
    right: 2.6%;
    height: 88.7%;
    .slick {
      &-list,
      &-track {
        height: 100%;
        overflow: visible;
      }
    }
  }
  &-slide {
    position: absolute;
  }
  &-slide-content {
    &.pos-xy {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -40%);
    }
    text-align: center;
  }
}

@media (max-width: 1020px) {
  .hero {
    &-slide-content {
      &.pos-xy {
        transform: translate(-50%, -50%);
      }
    }
  }
}
@media (max-width: 767.98px) {
  .hero {
    &-banner {
      padding: 0;
    }
    &-slider {
      top: 14.5%;
      left: 4.5%;
      right: 4.5%;
      height: 85.5%;
    }
  }
}

@media (max-width: 575.98px) {
  .hero {
    &-slide-content {
      h3 {
        margin-bottom: 1rem;
      }
      p {
        margin-bottom: 0;
        white-space: nowrap;
      }
    }

  }
}
