.marquee {
  font-size: 28px;
  text-transform: uppercase;
  color: #fff;
  overflow: hidden;
  height: 87px;
  transform: rotate(2.5deg);
  background-color: #8D0210;
  position: relative;
  z-index: 6;
  top: 0;
  width: calc(100% - 1px);

  div {
    display: block;
    width: 300%;
    padding: 20px 0;
    position: absolute;
    animation: marquee2 12s linear infinite;
  }

  span {
    float: left;
    width: 200%;
  }
}

/* keyframe */
@keyframes marquee2 {
  0% {
   left: 0;
  }

  100% {
   left: -100%;
  }
}
@keyframes marquee1 {
  0% {
   right: 0;
  }

  100% {
   right: -100%;
  }
}
.strip-2 {
  transform: rotate(-2.5deg);
  background-color: #730611;
  margin-bottom: -106px;
  position: relative;
  top: -91px;
  z-index: 2;
  div {
    animation: marquee1 12s linear infinite;
  }
 
}

@media (max-width:767.98px) {
  .marquee {
    font-size: 1.1rem;
    height: 55px;
    top: -37px;
    transform: rotate(4.5deg);

    img {
      width: 20px;
    }
    div{
      animation: marquee2 7s linear infinite;
    }
  }

  .strip-2 {
    transform: rotate(-2.5deg);
    margin-bottom: -95px;
    top: -91px;
    div{
      animation: marquee1 7s linear infinite;
    }
  }
}