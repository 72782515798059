.main {
  max-width: 1440px;
  margin: 0 auto;
  margin-top: 10rem;
  border-radius: 2rem;
  // height: 100%;
  position: relative;
  overflow-x: hidden;

  border: 3px solid #2f1315;
  background: linear-gradient(180deg, #2f1314 0%, #4f1d1b 100%);

  background: rgb(40, 17, 18);
  background: linear-gradient(
    180deg,
    rgba(40, 17, 18) 0%,
    rgba(104, 37, 33, 1) 100%
  );

  &-inner {
    // height: 100%;
    overflow-x: hidden;
    // background: url(../img/bgs/main-footer-bg.png) repeat-x center bottom;
  }
}

.page {
  &-header {
    text-align: center;
    // background: url(../../assets/img/bgs/page-header.jpg) no-repeat center top;
    background-size: cover;
    padding: 90px 0 70px;
    margin-bottom: 40px;
    &.bg-t {
      background: transparent;
    }
  }
  &-title {
    margin: 0;
  }
}
.section {
  &-header {
    text-align: center;
    padding: 60px 0 40px;
  }
  &-title {
    margin-bottom: 4rem;
  }
  &-sub-title {
    font-size: 2.4rem;
    color: $primary;
    margin-bottom: 0;
  }
  &-video-details {
    .section-header {
      padding: 50px 0px;
    }
    .block-large .block-footer {
      margin-bottom: 0;
    }
    .section-title {
      margin-bottom: 0;
    }
    .section-sub-title {
      margin-top: 15px;
    }
  }
}

//Singer Block Start Here

.block {
  position: relative;
  &-gradient-border {
    background: linear-gradient(0deg, #7c190b, #f50400 50%, #7c190b);
    border-radius: 1rem;
    box-sizing: border-box;
    overflow: hidden;
    padding: 4px;
    position: relative;
    &::before {
      background: #682521;
      border-radius: 1rem !important;
      bottom: 4px;
      content: '';
      left: 4px;
      position: absolute;
      right: 4px;
      top: 4px;
    }
    &.c-1 {
      &::before {
        background: #411918 !important;
      }
    }
    &.thin {
      &::before {
        right: 2px;
        top: 2px;
        left: 2px;
        bottom: 2px;
      }
    }
    & > * {
      position: relative;
      width: 100%;
      z-index: 1;
    }
  }

  &-gradient-border-group {
    @media (max-width: 767.98px) {
      background: linear-gradient(0deg, #7c190b, #f50400 50%, #7c190b);
      box-sizing: border-box;
      overflow: hidden;
      padding: 4px;
      position: relative;
      border-radius: 1rem;

      &::before {
        background: #682521;
        border-radius: 1rem !important;
        bottom: 4px;
        content: '';
        left: 4px;
        position: absolute;
        right: 4px;
        top: 4px;
      }
    }
    & > * {
      @media (min-width: 768px) {
        background: linear-gradient(0deg, #7c190b, #f50400 50%, #7c190b);
        box-sizing: border-box;
        overflow: hidden;
        padding: 4px;
        position: relative;
        border-radius: 1rem;

        &::before {
          background: #682521;
          border-radius: 1rem !important;
          bottom: 4px;
          content: '';
          left: 4px;
          position: absolute;
          right: 4px;
          top: 4px;
        }

        &.c-1 {
          &::before {
            background: #411918 !important;
          }
        }
        &.thin {
          &::before {
            right: 2px;
            top: 2px;
            left: 2px;
            bottom: 2px;
          }
        }
        & > * {
          position: relative;
          width: 100%;
          z-index: 1;
        }
      }
      @media (max-width: 767.98px) {
        border-bottom: 2px solid #8f1509;
        text-align: center;
        &:last-child {
          border: none;
        }
      }
    }
  }

  &-title {
    font-size: 2rem;
    font-weight: 500;
    margin-bottom: 1rem;
    text-align: center;
    color: $primary;
  }
  &-sub-title {
    font-size: 1.4rem;
    text-align: center;
    margin: 0;
  }
  &-footer {
    padding-top: 2rem;
    margin-bottom: 5rem;
    &-top {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
  &-seprator {
    height: 4px;
    // background: url(../img/seprator/seprator.jpg) no-repeat center center;
    background-size: 100% 100%;
    margin: 1rem 0;
  }
  &-img-wrapper {
    position: relative;
    display: block;
  }
  &-img {
    width: 100%;
    background: radial-gradient(
          circle at 100% 100%,
          #7c190b 0,
          #7c190b 16px,
          transparent 16px
        )
        0% 0%/20px 20px no-repeat,
      radial-gradient(
          circle at 0 100%,
          #7c190b 0,
          #7c190b 16px,
          transparent 16px
        )
        100% 0%/20px 20px no-repeat,
      radial-gradient(
          circle at 100% 0,
          #7c190b 0,
          #7c190b 16px,
          transparent 16px
        )
        0% 100%/20px 20px no-repeat,
      radial-gradient(circle at 0 0, #7c190b 0, #7c190b 16px, transparent 16px)
        100% 100%/20px 20px no-repeat,
      linear-gradient(#7c190b, #7c190b) 50% 50% / calc(100% - 8px)
        calc(100% - 40px) no-repeat,
      linear-gradient(#7c190b, #7c190b) 50% 50% / calc(100% - 40px)
        calc(100% - 8px) no-repeat,
      linear-gradient(
        0deg,
        rgba(124, 25, 11, 1) 0%,
        rgba(245, 4, 0, 1) 50%,
        rgba(124, 25, 11, 1) 100%
      );
    border-radius: 25px;
    padding: 4px;
    box-sizing: border-box;
  }
  &-overlay {
    &::before {
      content: '';
      border-radius: 20px;
      position: absolute;
      left: 4px;
      right: 4px;
      bottom: 4px;
      top: 4px;
      background: rgb(0, 0, 0);
      background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 1) 0%,
        rgba(0, 0, 0, 0) 100%
      );
    }
  }
  &-bottom-content {
    position: absolute;
    left: 4px;
    right: 4px;
    bottom: 20px;
    padding: 0 10px;
    .block {
      &-title {
        color: $light;
      }
      &-sub-title {
        font-size: 1.4rem;
      }
    }
  }
  &-lock,
  &-play {
    width: 40px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -60%);
    font-size: 6rem;
    &::before {
      color: $primary;
    }
  }
  &-locked {
    .block-overlay {
      &::before {
        background: rgb(0, 0, 0);
        background: linear-gradient(
          0deg,
          rgba(0, 0, 0, 1) 0%,
          rgba(0, 0, 0, 0.6) 55%,
          rgba(0, 0, 0, 0.7) 100%
        );
      }
    }
  }
  &-thumb {
    .block {
      &-footer {
        padding-top: 1.5rem;
      }
      &-overlay {
        &::before {
          background: rgb(0, 0, 0, 0.4);
        }
      }
      &-sub-title {
        font-size: 1.4rem;
        color: $light;
      }
      &-lock,
      &-play {
        width: 20px;
        position: absolute;
        left: 50%;
        top: 50%;
        font-size: 3.5rem;
        transform: translate(-50%, -50%);
      }
    }
    &.slick-center {
      .block {
        &-sub-title {
          color: $primary;
        }
        &-play {
          display: none;
        }
        &-overlay {
          &::before {
            display: none;
          }
          &::after {
            content: '';
            width: 0;
            height: 0;
            border-left: 14px solid transparent;
            border-right: 14px solid transparent;
            border-bottom: 14px solid $primary;
            position: absolute;
            left: 50%;
            top: -14px;
            transform: translateX(-50%);
          }
        }
      }
    }
  }
  &-large {
    .block {
      &-footer {
        padding-top: 3rem;
      }
      &-title,
      &-sub-title {
        text-align: left;
      }
      &-sub-title {
        font-size: 1.6rem;
      }
    }
  }
  &-card {
    width: 100%;
    text-align: center;
    padding: 2rem;
    box-sizing: border-box;
    &-img {
      height: 100px;
      object-fit: contain;
    }
    &-footer {
      margin-top: 2rem;
    }
    &-title {
      font-size: 1.6rem;
    }
  }
}

#videoIframe {
  height: 400px;
}

@media (min-width: 768px) {
  .blocks-mb-md-70 {
    .block-footer {
      margin-bottom: 70px;
    }
  }
  .gutter-md-70 {
    padding-left: 35px;
    padding-right: 35px;
    .row {
      margin: 0 -35px;
      .col {
        padding-left: 35px;
        padding-right: 35px;
      }
    }
  }
  .gutter-md-30 {
    padding-left: 15px;
    padding-right: 15px;
    .row {
      margin: 0 -15px;
      .col {
        padding-left: 15px;
        padding-right: 15px;
      }
    }
  }
}

@media (max-width: 1000px) {
  .center-zoom {
    .block {
      &-title {
        font-size: 1.6rem;
      }
      &-sub-title {
        font-size: 1.2rem;
      }
    }
  }
}

@media (max-width: 575.98px) {
  .blocks-mb-50 {
    .block-footer {
      margin-bottom: 50px;
    }
  }
  #videoIframe {
    height: 200px;
  }
  .section {
    &-header {
      text-align: center;
      padding: 40px 0 30px;
    }
    &-title {
      margin-bottom: 3rem;
    }
    &-video-details {
      .section {
        &-sub-title {
          font-size: 2rem;
          margin-top: 10px;
        }
        &-header {
          padding: 35px 0 40px;
        }
      }
    }
  }

  .block {
    &-footer {
      margin-bottom: 4rem;
      &-border {
        margin-top: 0.5rem;
      }
    }
    &-thumb {
      .block {
        &-caption {
          font-size: 1.2rem;
        }
      }
    }
    &-large {
      .block {
        &-footer {
          padding-top: 2rem;
        }
        &-sub-title {
          font-size: 1.4rem;
        }
      }
    }
  }
  .center-zoom {
    .block {
      &-title {
        font-size: 1.6rem;
      }
      &-sub-title {
        font-size: 1.2rem;
      }
    }
  }
}

@media (max-width: 460px) {
  .center-zoom {
    .block {
      &-title {
        font-size: 1.4rem;
        margin-bottom: 0.5rem;
        white-space: nowrap;
      }
      &-sub-title {
        font-size: 0.9rem;
      }
    }
  }
}

@media (max-width: 767.9px) {
  .main {
    border-top: 2px solid #8a261f;
    border-left: 0;
    border-right: 0;
  }
}

@media (min-width: 1200px) {
  .block {
    &-gradient-border-group {
      & > * {
        &:first-child {
          border-radius: 1rem 0 0 1rem;
          &::before {
            right: 0px;
            border-top-right-radius: 0 !important;
            border-bottom-right-radius: 0 !important;
          }
        }
        &:last-child {
          border-radius: 0 1rem 1rem 0;
          &::before {
            left: 4px;
            border-top-left-radius: 0 !important;
            border-bottom-left-radius: 0 !important;
          }
        }
        &:not(:first-child):not(:last-child) {
          border-radius: 0;
          &::before {
            left: 4px;
            right: 0px;
            border-radius: 0 !important;
          }
        }
      }
    }
  }
}
