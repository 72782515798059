.steps {
  &-wrapper {
    border-radius: 17px;
    background: #651513;
    padding: 6rem 5rem 5rem;
  }
  &-item {
    text-align: center;
  }
  &-img {
    margin-bottom: 1.5rem;
  }
  &-title {
    font-size: 1.5rem;
    text-transform: uppercase;
    line-height: 1;
    font-weight: 400;
    margin-bottom: 1.5rem;
  }
  &-discription {
    font-size: 1.8rem;
    line-height: 1.22;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 0;
  }
}

@media (max-width: 767.98px) {
  .steps {
    &-wrapper {
      border-radius: 12px;
      padding: 2.8rem 1rem;
    }
    &-img {
      margin-bottom: 1rem;
    }
    &-title {
      font-size: 1.2rem;
    }
    &-discription {
      font-size: 1rem;
    }
  }
}
