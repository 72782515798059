//profile
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 0px;
  background-color: #f5f5f5;
}

body:has(.nav-item.show.dropdown) {
  overflow: hidden;
}

::-webkit-scrollbar {
  width: 8px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  border-radius: 0px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #333;
}

.tu-profile-dropdown {
  .dropdown-menu {
    // background: $danger;
    background: linear-gradient(180deg, #5b2322 0%, #371717 100%);
    min-width: 337px;
    width: 100%;
    padding: 22px 0 0 0;
    border-radius: 15px;
    color: $light;

    @media (max-height: 1000px) {
      overflow-y: auto;
      height: 78vh;
    }

    @media (max-width: 575.9px) {
      height: 76vh;
      inset: unset !important;
      right: 0 !important;
      top: 38px !important;
      transform: none !important;
    }
  }

  .dropdown-toggle {
    &:after {
      display: none;
    }

    .thumbnail-image {
      position: relative;
      z-index: 2;
    }
  }

  ul.tu-user-details {
    list-style: none;
    display: block;
    padding: 0 22px;

    li {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .apper {
        width: 100%;
      }

      span {
        font-size: 14px;
        font-weight: 700;
        letter-spacing: 0px;
        color: $light;
        text-align: left;
        white-space: nowrap;
        min-width: 99px;
      }

      img {
        width: 14px;
        height: 14px;
      }

      .tu-input-wrapper {
        display: flex;
        align-items: baseline;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 1rem;

        input {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .error_message {
          font-size: 14px;
          margin-right: 2rem;
          color: #fff;
        }

        h6 {
          font-size: 1.4rem;
          overflow: hidden;
          text-overflow: ellipsis;
          word-break: break-word;
          line-height: 19px;
        }

        select {
          height: 33px;

          option {
            background-color: $danger;
            border: 0 !important;
            box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
          }
        }
      }

      .gender-wrapper {
        div {
          width: 100%;
        }
      }

      input.tu-user-input,
      select.tu-user-input {
        background: transparent;
        border: none;
        font-size: 14px;
        line-height: 30px;
        color: $light;
        font-weight: 700;
        width: calc(100% - 20px);
        padding-left: 0;
        display: block;

        @media (max-width: 574.98px) {
          font-size: 12px;
        }

        &:disabled {
          padding-left: 10px;
        }

        &.edit {
          border-bottom: 1px solid $light;
          border-radius: 0;
        }

        &:focus-visible {
          outline: none;
        }

        &::placeholder {
          color: $light;
          font-weight: 700;
        }
      }

      button {
        background: transparent;
        border: none;
      }

      .tu-save-btn {
        color: $primary;
        background-color: $light;
        border-color: $light;
        width: 28px;
        font-size: 17px;
        border-radius: 4px;
        height: 25px;
        line-height: 10px;
        padding: 0 6px;
      }
    }
  }
}

.signup-avtar {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 1px solid $light;
  background-color: $light;

  position: relative;
  background-image: url('~/public/assets/images/avatar.png');

  &::after {
    background-image: url('~/public/assets/images/icons/plus-icon.png');
    content: '';
    top: 39px;
    right: 0;
    position: absolute;
    width: 24px;
    height: 24px;
  }
}

.tu-coins-section,
.tu-referrals-section {
  border-top: 1px solid rgb(157 173 210 / 40%);
  padding: 10px 22px;

  .tu-content-row {
    display: flex;
    justify-content: space-between;

    .tu-content {
      display: flex;
      justify-content: space-between;
      width: 65%;

      p {
        font-size: 14px;
        line-height: 28px;
        color: $light;
        font-weight: 700;
        margin-bottom: 3px;
        text-align: left;
        min-width: 12px;
      }
    }

    .btn {
      width: 79px;
      height: 30px;
      font-size: 1rem;
      line-height: 22px;
      padding: 3px 0;
      border-radius: 4px;
      background-color: $dark;
    }

    .claim-btn {
      position: relative;
      top: 15px;
    }
  }
}

.logout-btn {
  // border-top: 1px solid rgb(157 173 210 / 40%);

  a.btn {
    height: 43px;
    width: 117px;
    color: #cf152d !important;
  }
}

.tu-referrals-section {
  padding-top: 24px;
}

.tu-profile-dropdown.show:after {
  content: '';
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  position: fixed;
  background: rgba(0, 0, 0, 0.7);
  z-index: 1;
}

//tooltip profile menu start

.tooltip {
  &-profile-avatar {
    &.show {
      opacity: 1;
    }

    .arrow {
      &::before {
        border-width: 0.8rem 0.8rem 0;
        border-top-color: #fff;
        left: -101px;
        top: 24px;
      }
    }

    .tooltip {
      &-inner {
        background-color: transparent;
        position: absolute;
        left: -100px;
        top: 25px;
      }
    }

    .avatar-options {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      width: 200px;
      background-color: #fff;
      padding: 15px;
      border-radius: 10px;

      img {
        width: 40px;
        display: flex;
        cursor: pointer;
      }
    }
  }
}

.claim-ticket {
  position: relative;
  bottom: 16px;
}

//tooltip profile menu end
.error_red {
  color: #ce1727 !important;
  margin-top: 44px;
}

@media (max-width: 574.98px) {
  .error_message {
    font-size: 9px;
  }

  .dropdown-menu.show {
    min-width: 337px;
  }
}

@media (max-width: 344.98px) {
  .dropdown-menu.show {
    min-width: 310px;
  }
}
