$primary: #e51e2b;
$secondary: #560c10;
$danger: #be1324;
$light: #ffffff;
$dark: #000000;

.table {
  width: 100%;
  position: relative;
  margin: 0;
  z-index: 1;
  &-wrapper {
    position: relative;
    border-radius: 1rem;
    background: linear-gradient(
      0deg,
      rgba(124, 25, 11, 1) 0%,
      rgba(245, 4, 0, 1) 50%,
      rgba(124, 25, 11, 1) 100%
    );
    padding: 4px;
    box-sizing: border-box;
    overflow: hidden;
    &::before {
      background: #5d211e;
      border-radius: 1rem;
      content: '';
      position: absolute;
      left: 4px;
      right: 4px;
      top: 4px;
      bottom: 4px;
    }
    .pic {
      width: 2rem;
      border-radius: 100%;
      margin-right: 1.4rem;
    }
  }
  thead {
    th {
      border: unset;
      color: $primary;
      font-family: 'TCCC Unity', sans-serif;
      // font-size: 1.8rem;
      // font-weight: bold;
      padding: 1.4rem 0.75rem;
      &:first-child,
      &:last-child {
        text-align: center;
      }
      &:nth-child(2) {
        padding-left: 4.5rem;
      }
    }
  }
  td {
    font-family: 'TCCC Unity', sans-serif;
    font-weight: normal;
    color: #fff;
    border-top: 1px solid rgba(124, 25, 11, 1);
    &:first-child,
    &:last-child {
      text-align: center;
    }
  }

  &.leader-board-table {
    .highlight {
      background-color: #f50400;
    }
    thead {
      tr {
        th {
          &:nth-child(2) {
            padding-left: 1.4rem;
            text-align: center;
          }
        }
        &:first-child {
          th,
          td {
            &:first-child {
              border-top-left-radius: 1rem;
            }
            &:last-child {
              border-top-right-radius: 1rem;
            }
          }
        }
      }
    }
    tbody {
      border-radius: 1rem;
      tr {
        td {
          &:first-child {
            img {
              max-width: 100%;
              width: 35px;
              margin: 5px;
            }
          }
          .pic {
            width: 30px;
            height: 30px;
            border-radius: 50%;
          }
        }
        &:last-child {
          border-radius: 0 0 1rem 1rem;
          td {
            &:first-child {
              border-radius: 0 0 0 1rem;
            }
            &:last-child {
              border-radius: 0 0 1rem 0;
            }
          }
        }
      }
    }
    .calendar {
      &-title {
        max-width: 25%;
      }
      &-icon {
        cursor: pointer;
        width: 25px;
      }
    }
  }
}

@media (min-width: 576px) {
  .table {
    &-wrapper {
      .pic {
        width: 3rem;
      }
    }
    thead {
      th {
        font-size: 1.8rem;
        line-height: 2rem;
      }
    }
    td {
      font-size: 1.6rem;
      padding: 0.8rem;
    }
  }
}

@media (min-width: 992px) {
  .table {
    &.leader-board-table {
      .calendar {
        &-title {
          max-width: 15%;
        }
      }
    }
  }
}
