.tu-country-select {
    li {
        position: relative;
        background-repeat: no-repeat;

        &:hover {
            background-color: #d5d5d5 !important;
        }

        .ReactFlagsSelect-module_selectOptionValue__vS99- {
            align-items: baseline;

            span.ReactFlagsSelect-module_selectFlag__2q5gC {
                position: absolute;

                svg {
                    display: none;
                }
            }

            .ReactFlagsSelect-module_label__27pw9 {
                padding-left: 40px;
            }
        }
    }

    button#rfs-btn {
        span.ReactFlagsSelect-module_selectFlag__2q5gC {
            position: absolute;

            svg {
                display: none;
            }
        }

        span.ReactFlagsSelect-module_label__27pw9 {
            padding-left: 40px;
        }
    }
}

#rfs-AF,
.selected_country_AF {
    span.ReactFlagsSelect-module_selectFlag__2q5gC::after {

        background: url('~/public/assets/images/flags/afghanistan.png') left top no-repeat;
        content: "";
        width: 29px;
        height: 19px;
        display: block;
        background-size: 30px;
    }
}

$countryFlagData: (
  AU: 'australia',
  BD: 'bangladesh',
  GB: 'england',
  IN: 'india',
  NL: 'netherlands',
  NZ: 'newzeland',
  PK: 'pakistan',
  ZA: 'southafrica',
  LK: 'srilanka',
);

@mixin flag-styles($country) {
  span.ReactFlagsSelect-module_selectFlag__2q5gC::after {
    background: url('~/public/assets/images/flags/#{$country}.png') left top no-repeat;
    content: "";
    width: 29px;
    height: 19px;
    display: block;
    background-size: 30px;
  }
}

@each $countryCode, $flagImage in $countryFlagData {
  $selector: unquote("#rfs-#{$countryCode}, .selected_country_#{$countryCode}");

  #{$selector} {
    @include flag-styles($flagImage);
  }
}
