$body-bg: rgba(190, 19, 36, 1) !important;
$map-height: 100vh;
$container-height: 100%;

body {
  margin: 0;
  padding: 0;
}

.scrollable-map {
  height: $map-height;
  background-color: transparent !important;
  z-index: 1;

  .leaflet {
    &-container {
      height: $container-height;
      touch-action: pan-y;
      overflow-x: hidden;
      background-color: transparent !important;
    }

    &-control-zoom {
      display: none;
    }
  }

  .leaflet-bottom.leaflet-right {
    display: none;
  }
}

.mapSectionContainer {
  background: url("~/public/assets/images/bgs/believo-meter-background.png") !important;
  background-repeat: no-repeat !important;
  background-position: 0% 117% !important;
}

.mapSection {
  padding: 40px 0px;

  .container {
    display: flex;
    overflow: hidden;
  }

  &-left {
    max-width: 800px;
    position: relative;
    width: 60%;

    .scrollable-map {
      width: 840px;
      position: inherit !important;
      overflow: visible !important;
      inset: 0 -100px !important;

      @media (max-width: 1500px) {

        inset: 0 -170px !important;
        transform: scale(0.9);

        .mapSection canvas {
          height: 280px !important;
          width: 449px !important;
        }
      }

      @media (max-width: 1400px) {
        inset: 0 -170px !important;
        transform: scale(0.9);

        .mapSection canvas {
          height: 280px !important;
          width: 449px !important;
        }
      }

      @media (max-width: 1180px) {
        inset: 0 -250px !important;

        .mapSection canvas {
          height: 280px !important;
          width: 449px !important;
        }
      }

      @media (max-width: 991px) {
        inset: 0 -100px !important;
      }


      @media (max-width: 767px) and (min-width:591px) {
        inset: 0 -287px !important;


      }

      @media (max-width: 590px) and (min-width:320px) {
        inset: 0 -402px !important;

      }

      // @media (max-width: 450px) and (min-width:320px) {
      //   inset: 0 -124px !important;

      // }
    }
  }

  &-title {
    right: -6.9rem;
    position: absolute;
    top: 23px;
  }

  &-right {
    max-width: 450px;
    padding: 100px 0 0 0px;

    .heading {
      margin: 0;
      padding: 0;
      line-height: 58px;
      font-size: 30px;

      @media (max-width: 576px) {
        font-size: 30px;
      }
    }

    .sub-heading {
      margin: 0;
      padding: 0;
      line-height: 23px;

    }

    @media (max-width: 991px) {
      margin-left: auto;
      margin-right: auto;
      padding-top: 0px;
      padding-bottom: 40px;
    }
  }

  .mapSection-container {
    @media (max-width: 1440px) {
      transform: scale(0.9);
    }

    @media (max-width: 591px) {
      transform: scale(0.7);
    }
  }
}

.believo-meter-section {
  .meter {
    &-wrapper {
      margin: 0 0 30px 0;
    }

    &-title {
      margin: 0;
      font-size: 32px;
      font-weight: 500;
      line-height: 51px;
    }

    &-sub-heading {
      margin-bottom: 33px;
      padding: 0;
      font-size: 20px;
      line-height: 30px;
    }

    &-list-item {
      list-style-type: none;
      justify-content: center;
      padding-left: 0;
      border: none;
      overflow-x: auto;
      max-width: 1440px;
      width: calc(100% - 40px);

      &:hover {
        border: none;
      }
    }

    ::-webkit-scrollbar-track {
      -webkit-box-shadow: none;
      box-shadow: none;
      background-color: transparent;
    }

    ::-webkit-scrollbar {
      height: 0px;
      background-color: transparent;
    }

    ::-webkit-scrollbar-thumb {
      background-color: transparent;
    }
  }

  .slick-slide.slick-active+.slick-active {
    transform: scale(1);
  }

  .slick-slider {
    max-width: 1100px;
    margin: 0 auto;
  }

  .slick-list {
    max-width: 945px;
    margin: 0 auto;

    @media (max-width:767px) {
      max-width: 1800px;
    }
  }

  .slick-slide {
    display: flex !important;
    justify-content: center;
  }

  a.leaflet-popup-close-button {
    display: none;
  }

  &::after {
    display: none !important;
  }
}

.my-progress-bar .CircularProgressbar {
  margin: 0 !important;

  @media (max-width:767px) {
    width: 87px !important;
  }
}

.CircularProgressbar .CircularProgressbar-trail {
  stroke: #FFF !important;
}

.my-progress-bar .CircularProgressbar .CircularProgressbar-text {
  font-size: 10px;
  /* Change the font size */
  font-weight: bold;
  /* Change the font weight */
  /* Add or modify any other desired properties */
}

.meter-container li {
  padding: 10px;
}

.scrollable-map {
  width: 800px;
  height: 700px;
  background-color: transparent;
  right: 15rem;
}

.leaflet-popup-content-wrapper {
  background: transparent !important;
}

.leaflet-popup-tip {
  display: none;
}

.leaflet-popup {
  margin-bottom: -15px !important;
}

.leaflet-popup-content {
  width: 100%;
  max-width: 229px;
  max-height: 53px;
  margin: 0 !important;

  .map-popup {
    display: flex;

    h3 {
      font-size: 1.5rem;
    }

    .popup-pulse {
      border-radius: 12px 0 0 12px;
      padding-left: 11px;
      padding-right: 10px;
      padding-top: 18px;
      display: flex;
      align-items: center;
      border-radius: 10px 0px 0px 10px;
      background: #000;
      color: #FFF;

      .sub-heading h3 {
        color: #FFF;
        text-align: center;
        font-size: 15px;
        font-weight: 900;
        line-height: 19px;
      }

    }

    .popup-title {
      border-radius: 0 12px 12px 0;
      padding: 7px 9px;
      display: flex;
      align-items: center;
      background: #560C10;

      h3 {
        margin-bottom: 0;
        color: #FFF;
        font-size: 10px;
        font-weight: 900;
        line-height: 14px;
      }
    }
  }
}

.graph-wrapper {
  .line-data {
    canvas {
      display: flex !important;
      width: 100%;
      max-width: 400px;
      height: 256px;
    }
  }
}

@media only screen and (max-width: 1225px) {
  .believo-meter-section {
    .meter {
      &-list-item {
        width: 100%;
      }
    }
  }

  .graph-wrapper {
    .line-data {
      canvas {
        display: flex !important;
        max-width: 100% !important;
        padding: 0 40px;
        margin: 0 auto;
        height: auto !important;
        height: 283px !important;
        width: 389px !important;
      }
    }
  }

  .leaflet-container {
    margin: 0 auto;
  }

  .map-text {
    h4 {
      right: 1rem !important;
    }
  }

  .mapSection {
    .container {
      display: flex;
    }

    &-title {
      right: -6.7rem;
      position: absolute;
      top: 46px;
    }
  }
}

@media only screen and (max-width: 2560px) {

  // Map section CSS start for responsive

  .mapSection-left {
    transform: scale(0.9) translate(-60%, 0);
    left: 41% !important;
    top: 49%;
    max-height: 687px;

    @media (max-width:1500px) {
      left: 50% !important;
    }

    .leaflet-pane {
      transform: none !important;
    }

  }
}

@media only screen and (max-width: 992px) {
  .map-text {
    h4 {
      right: 9rem !important;
    }
  }

  .mapSectionContainer {
    background-position: 0% 100% !important
  }

  .mapSection {
    background-size: cover;

    .container {
      display: block;
    }

    &-left {
      width: 100%;

      .scrollable-map {
        position: relative !important;

      }
    }

    &-title {
      right: -8.5rem;
      position: absolute;
      top: 40px;
    }

    // Map section CSS start for responsive
    .mapSection-left {
      transform: scale(0.9) translate(-60%, 0);
      left: 50% !important;
      top: 49%;
      max-height: 632px;

      .leaflet-pane {
        transform: none !important;
      }
    }

  }
}

@media (max-width:767.98px) {
  .mapSectionContainer {
    background-image: none !important;
  }
}

@media only screen and (max-width: 449.98px) {
  .believo-meter-section {


    .meter {
      &-wrapper {
        margin: 0 auto 0px auto;
        max-width: 463px;
      }

      h4 {
        font-size: 2.6vw;
        line-height: 1.8rem;
        margin-bottom: 0;
        padding: 0 3rem;
      }

      &-title {
        font-size: 5.333vw;
        line-height: 26px;
        padding-top: 24px;
      }

      &-heading {
        font-size: 44px;
        line-height: 48px;
      }

      &-sub-heading {
        font-size: 1rem;
        line-height: 1.5rem;
      }

      &-list-item {
        margin: 0 auto;

        &:hover {
          border: none;
        }
      }

    }

  }
 .mapSection {
    background-size: cover;
   overflow: hidden;
 // Map section responsive
    .container {
      display: block;
    }

    &-left {
      width: 100%;

      .scrollable-map {
        position: relative !important;
        right: 0;

      }
    }

    &-title {
      right: 3.6rem;
      position: absolute;
      top: 82px;

    }


    // Map section responsive
    .mapSection-left {
      transform: scale(0.6) translate(-100%, -33%);
      left: 9%;
      max-height: 405px;
      top: 53%;

      h3 {
        font-size: 1.5rem;
      }

      .mapSection-title h4 {
        display: none;
      }

      .leaflet-pane {
        transform: none !important;
      }

      .map-popup {
        .popup-title {
          h3 {
            font-size: 8px
          }
        }
      }
    }
  }
}

.leaflet-popup-content .map-popup .popup-title {
  position: relative;
}

.leaflet-popup-content .map-popup .popup-title:after {
  content: '';
  border: 10px solid transparent;
  border-top: 10px solid #560c10;
  position: absolute;
  bottom: -17px;
  left: 9px;
  z-index: 1;
}

@media only screen and (max-width: 320px) {
  .mapSection-left {

    transform: scale(0.6) translate(-113%, -34%) !important;
    max-height: 405px !important;
    left: 0 !important;
    top: 53%;
  }
}

.progress-bar-wrapper {
  position: relative;
  width: 150px;
}

.progress-bar-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-49%, -50%);
  line-height: normal;
  font-size: 2.4rem;

  div {
    font-size: 1.2rem;
  }
}

.map-text {
  position: relative;

  h4 {
    position: absolute;
    top: 9rem;
    right: 9rem;
    font-size: 36px;
  }
}

@media (max-width:767px) {
  .believo-meter-section {
    .progress-bar-text {
      font-size: 1.5rem;

      div {
        font-size: 0.8rem;
      }
    }

    .slick-slide {
      padding-bottom: 0 !important;
    }
  }
}

.leaflet-fade-anim .leaflet-map-pane .leaflet-popup {
  bottom: -32px !important;
  left: -69px !important;
}

@media (max-width:767px) {
  .meter-container {

    button.slick-arrow.slick-prev,
    button.slick-arrow.slick-next {
      display: block !important;
    }

    .slick-prev {
      left: -10px
    }

    .slick-next {
      right: -14px;
    }

    .slick-prev,
    .slick-next {
      &::before {
        width: 25px;
        height: 25px;
        background-size: contain;
        position: relative;
        top: 7px;
      }
    }

  }

}