.btn {
  &:focus-visible,
  &:focus-within,
  &:focus {
    outline: 0;
    box-shadow: none;
  }
  &-light {
    color: #cf152d !important;
  }
  &-danger {
    &:hover,
    &:focus-visible,
    &:focus-within,
    &:focus {
      background-color: $danger;
    }
  }
  &-sm {
    font-weight: bold;
  }
  &-xs {
    font-size: 1.6rem;
    line-height: 1.6rem;
    padding: 0.6rem 1.2rem;
    border-radius: 0.4rem;
  }
  &-share {
    position: absolute;
    padding: 9px;
    border-radius: 9px 0 0 9px;
    right: 0;
    top: 86px;
    z-index: 4;
  }
}

@media (max-width: 575.98px) {
  .btn {
    font-size: 2.4rem;
    border-radius: 0.8rem;
    &-sm {
      font-size: 1.6rem;
      border-radius: 0.6rem;
    }
    &-xs {
      font-size: 1.4rem;
      line-height: 1.4rem;
    }
  }
}
