.section-rules {
    ol {
        font-size: 2rem;
        font-weight: 700;
        padding-left: 115px;
        padding-bottom: 102px;
    }

    @media (max-width:767.98px) {
        ol {
            font-size: 1rem;
            padding-left: 16px;
            padding-bottom: 44px;
        }

        h2 {
            font-size: 1.4rem;
        }
    }
}

.claim-section {
    .claim-card.col::after {
        display: none;
    }

    .card-body {
        padding: 3.25rem;
    }

    @media (max-width:991.98px) {
        .row {
            max-width: 654px;
        }

        .claim-card {

            img {
                max-width: 56px;
            }
        }

    }

    @media (max-width:767.98px) {
        .row {
            max-width: 370px;
        }

        .card {
            max-width: 104px;

            &-body {
                padding: 12px 4px;
            }
        }

        h5 {
            font-size: 0.7rem;
            line-height: 1.2rem;
        }

        h4 {
            font-size: 0.8rem;
            line-height: 1.4rem;
        }

        .claim-card {
            img {
                max-width: 42px;
            }

        }
    }
}