.grab-section {
  margin-top: 5rem;
  .title {
    font-size: 3.6rem;
    line-height: 1.2;
    text-transform: uppercase;
  }
  .sub-title {
    font-size: 2rem;
    margin-bottom: 3rem;
  }
  .img {
    max-width: 18.8rem;
  }
}

@media (min-width: 767.98px) {
  .grab-section {
    max-width: 49rem;
    margin-top: 0;
  }
}

@media (max-width: 575.98px) {
  .grab-section {
    .title {
      font-size: calc(1.8rem + 1vw);
      margin-bottom: 1.5rem;
    }
    .sub-title {
      font-size: calc(1.3rem + 0.2vw);
      margin-bottom: 1.5rem;
    }
    .img {
      max-width: 100px;
    }
  }
}

@media (max-width: 575.98px) {
  .grab-section {
    .title {
      font-size: 4.8vw;
    }
    .sub-title {
      font-size: 3.4vw;
    }
  }
}
