.bn {
    //Sidebar

    .sidebar-main {
        ul {
            li {
                a {
                    font-size: 1.2rem;

                    @media (max-width:767.98px) {
                        font-size: 1.2rem;
                    }
                }

            }
        }

        h2 {
            font-size: 1.2rem;
        }

        .tu-language-select {
            select {
                font-size: 1.2rem !important;
            }
        }

    }

    //Banner
    .banner-content {
        h1 {
            line-height: 5rem;
        }

        h2 {
            line-height: 4.3rem;
        }

        h3 {
            line-height: 5rem;
        }

        @media (max-width:767.98px) {
            h1 {
                line-height: 2.5rem;
            }

            h2 {
                line-height: 2;
            }

            h3 {
                line-height: 1.7;
            }
        }
    }

    .wrapper {
        h5 {
            line-height: 2.3rem;
        }
    }

    @media (max-width:767.98px) {
        h1 {
            line-height: 3rem;
        }

        h3 {
            line-height: 2.2rem;
        }

        h3 {
            line-height: 1.8rem;
        }

        .wrapper {
            h5 {
                line-height: 1.3rem;
            }
        }
    }


    //Home page middle section 
    .tu-middle-section-wrapper {
        .tu-worldcup-section:before {
            top: calc(100% - 13vw);

        }

        .tu-worldcup-section:after {
            top: calc(100% - 12vw);

        }

        @media (max-width:767.98px) {
            .tu-worldcup-section:after {
                top: 3vw;

            }

            .tu-worldcup-section::before {
                top: 2vw;

            }

            h2 {
                font-size: 1.5rem;
            }
        }
    }

    //modal
    .modal {

        h4 {
            font-size: 1.8rem;

            @media (max-width:767.98px) {
                font-size: 1.3rem;
            }
        }

        h3 {
            line-height: 3.7rem;
        }

        .form {
            &-label {
                font-size: 1.4rem;
            }

            @media (max-width:767.98px) {
                h3 {
                    font-size: 1.3rem !important;
                }

                .btn {
                    font-size: 1.1rem !important;
                }
            }

            .form {
                &-label {
                    font-size: 0.9rem;
                }
            }
        }

        //Profile menu 
        .tu-coins-section {
            .tu-content-row {
                .btn {
                    font-size: 0.8rem !important;
                }
            }

        }

        //How to claim page
        .claim-section {
            h5 {
                line-height: 1.8;
            }

        }

        @media (max-width:767.98px) {
            .section-rules {
                ol {
                    line-height: 1.8;
                }
            }

        }
    }

    //believo-meter-section
    .believo-meter-section {
        h4 {
            line-height: 3rem;
        }

        @media (max-width:767.98px) {
            h4 {
                line-height: 2.2rem;
            }
        }
    }

    .mapSection-title {
        h4 {
            font-size: 16px;
        }

    }

    .tu-language-select {
        select {
            width: 60px !important;

        }

        @media (max-width:767px) {}
    }

    .tu-content-row {
        a.btn {
            font-size: 0.8rem !important;
        }
    }

    .hero-img-wrapper {
        img {
            @media (max-width:767px) {
                margin-bottom: 12px;
            }
        }
    }

    @media (max-width:767px) {
        .bn-heading {
            line-height: 28px !important;
        }

        .bn-margin {
            margin-bottom: 5px;
        }

        .how-win-card {
            .card {
                &-text {
                    font-size: 9px;
                    line-height: 1.5;
                }

                &-title {
                    font-size: 9px;
                }
            }
        }

        .bn-heading-meter {
            padding: 0 36px;
            font-size: 12px;
        }

        .offcanvas-menu {
            max-width: 89%;
            width: 282px;

        }

        .header {
            .tu-language-select {
                width: 40px;
            }
        }

        .sidebar-main {
            .tu-language-select {
                width: calc(100% - 22px);
            }
        }

    }

}