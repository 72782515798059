.victory-wrapper {
    .content {
        max-width: 725px;
    }

    .btn {
        max-width: 127px;
        padding: 1.3rem 1.5rem;
    }

    .img-coins {
        img {
            max-width: 265px;
        }
    }

    .img-bottle {
        max-height: 162px;
    }

    .coins {
        max-height: 110px;
    }

    &.xy-center {
        max-width: 1126px;
        width: 100%;
    }

    &-step {
        font-size: 14px;
        position: relative;
        left: -10px;

        &::before,
        &::after {
            content: '';
            border-top: 2px dotted #CD0E2D;
            height: 1px;
            width: calc(50% - 28px);
            position: absolute;
            top: 11px;
        }

        &::before {
            left: 1px;
        }

        &::after {
            right: 1px;
        }
    }

    .step-first {
        &::before {
            display: none;
        }
    }

    .step-last {
        &::after {
            display: none;
        }
    }

    @media (min-width: 768px) and (max-width: 1199.98px) {
        &.xy-center {
            max-width: calc(100% - 130px);
        }

        .content {
            h2 {
                font-size: 2.222vw;
            }
        }
    }

    @media (max-width: 767.98px) {
        &.xy-center {
            max-width: calc(100% - 80px);
        }

        .img-coins {
            img {
                width: 163px;
            }
        }

        .btn {
            font-size: 8px;
            padding: 3px 10px;
            text-wrap: nowrap;
        }

        h5 {
            font-size: 7px;
            line-height: 9px;
        }

        &-step {
            font-size: 7px;
            position: relative;
            left: -10px;

            &::before,
            &::after {
                content: '';
                border-top: 2px dotted #CD0E2D;
                height: 1px;
                width: calc(50% - 18px);
                position: absolute;
                top: 5px;
            }

            &::before {
                left: 1px;
            }

            &::after {
                right: 1px;
            }
        }
    }

    @media (max-width: 574.98px) {

        .content,
        .wrapper {
            max-width: 375px;
            width: 100%;

            h2 {
                font-size: 1.8rem;
            }
        }

        &.xy-center {
            max-width: 100%;
            padding: 0 20px;
        }
    }
}