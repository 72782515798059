.header {
  background-color: $dark;
  color: $light;
  position: fixed;
  z-index: 9;
  width: 100%;
  top: 0;
  min-height: 80px;

  &-top {
    background: $secondary;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  &-nav {
    width: 100%;
    padding-top: 1.8rem;
    padding-bottom: 1.8rem;
  }

  &-logo {
    height: 124px;
    margin-bottom: -60px;
  }

  //Uniques code component
  .tu-unique-code-wrapper {
    border: 1px solid $light;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px;

    input {
      font-size: 1.4rem;
      border: none;
      padding-right: 25px;
      background: transparent;
      color: $light;
      flex: 1;

      &::placeholder {
        color: $light;
      }

      &:focus-visible {
        outline: none;
      }
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus {
      border: none;
      -webkit-text-fill-color: #fff;
      -webkit-box-shadow: 0 0 0px 1000px #000 inset;
      transition: background-color 5000s ease-in-out 0s;
    }

    .btn {
      margin-left: -58px;
    }
  }
}

//My wallet component
.tu-wallet-link {
  color: inherit;
  font-size: min(1rem, 3vw);
  margin: auto 5px;
  font-size: 1.6rem;
  white-space: nowrap;
  &:hover,
  &:focus {
    text-decoration: none;
    color: inherit;
  }

  &:focus {
    box-shadow: none;
  }
}

.tu-profile-dropdown {
  .nav-link {
    padding: 0;
  }

  .thumbnail-image {
    min-width: 44px;
    min-height: 44px;
  }
}

.tu-common-modal.tu-wallet-modal {
  .modal-dialog {
    max-width: 732px;
    width: calc(100% - 30px);
    margin: 30px auto;
  }

  .modal-body {
    padding: 0 30px 52px;

    .tu-wallet-wrapper {
      margin-top: 30px;

      .tu-card {
        img {
          width: 90px !important;
          // object-fit: none !important;
          height: auto !important;
          border-bottom: none !important;
        }

        span {
          border-top: none !important;
        }
      }
    }

    .btn-primary {
      border-radius: 12px;
      margin-top: 3px;
      font-size: 24px;
      font-weight: 400;
      width: 100%;
      cursor: pointer;
    }

    .tu-coins-cards {
      display: flex;
      justify-content: space-around;
      background: #0386c4;
      padding: 22px 0;
      border-radius: 12px;
      position: relative;
      padding-bottom: 22px;

      .tu-card {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        justify-content: end;

        img {
          width: 100%;
          height: 73px;
          object-fit: contain;
          object-position: center;
          margin-bottom: 22px;
        }

        span {
          font-weight: 700;
          font-size: 22px;
          line-height: 45px;
          width: 100%;
          display: block;
          text-align: center;
          border-top: 1px solid rgba(0, 32, 105, 0.2);
        }

        .btn {
          max-width: 50%;
          margin: 0 auto;
          padding: 15px 0;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .header {
    &-top {
      padding-top: 0 !important;
      padding-bottom: 0 !important;

      .row {
        padding-top: 6px !important;
        padding-bottom: 6px !important;
      }
    }

    &-nav {
      padding-top: 1rem;
      padding-bottom: 1.1rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      min-height: 46px;
    }

    .tu-profile-dropdown {
      .nav-link {
        padding: 0;
      }

      .thumbnail-image {
        min-width: 32px;
        min-height: 32px;
      }
    }

    .tu-wallet-link {
      font-size: 1rem;
    }

    .header-logo {
      height: 54px;
      margin-bottom: -47px;
    }

    //Uniques code component
    .tu-unique-code-wrapper {
      padding: 0px 5px;

      input {
        font-size: 1rem;
        padding-right: 12px;
      }
      .btn {
        font-size: 10px;
        margin-left: -30px;
        margin-top: 5px;
        margin-bottom: 5px;
        padding: 5px 8px;
      }
    }
  }
  .tu-wallet-wrapper {
    .tu-coins-cards {
      &::after {
        border-top: none !important;
      }

      .btn {
        max-width: 80%;
      }
    }
  }
  .tu-language-select {
    position: relative;
    &::after {
      position: absolute;
      content: '';
      background-image: url('~/public/assets/images/language-dropdown.svg');
      top: 5px;
      width: 9px;
      height: 6px;
      background-repeat: no-repeat;
      background-size: contain;
      right: 0px !important;
      z-index: 0;
    }
    select {
      font-size: 10px !important;
    }
  }
}

@media (max-width: 600px) {
  .tu-wallet-wrapper {
    .tu-coins-cards {
      .btn {
        max-width: 90% !important;
        font-size: 14px !important;
      }
    }
  }
}
