.section {
  padding-top: 4.4rem;

  &-table {
    padding-top: 5.5rem;
  }

  &-header {
    text-align: center;
    padding: 0 1.6rem;
  }

  &-title,
  &-title-hero {
    line-height: 6.8rem;
    z-index: 1;
  }
  &-desc {
    font-size: 2rem;
    line-height: 2.5rem;
  }
  
  &-no-strip {
    &::after {
      display: none;
    }
  }
}

@media (max-width: 991.98px) {
  .section {

    &-title,
    &-title-hero {
      font-size: 4.5vw;
      line-height: 5.8vw;
    }

    &-desc {
      font-size: 2.42vw;
      line-height: 2.46vw;
    }

    &-sub-title {
      font-size: 3.2rem; 
      line-height: 3.4rem;
    }
  }
}

@media (max-width: 767.98px) {
  .section {
    padding-top: 3rem;

    &-table {
      padding-top: 1.5rem;
    }

    &-desc {
      font-size: 2.67vw;
      line-height: 3.5vw;
    }

    &-header {
      padding: 2rem 1.2rem 0 1.2rem;
    }
  }
}

@media (max-width: 575.98px) {
  .section {
    &-table {
      padding-top: 3.0rem;
    }
  }
}

@media (max-width: 430px) {
  .section {
    &-title {
      font-size: 1.8rem;
      line-height: 24px;
    
    }

    &-title-hero {
      font-size: 5vw;
      line-height: 5.2vw;
    }
    &-desc {
      font-size: 1rem;
      line-height: 1.4rem;
      margin-bottom: 1.5rem;
    }
    &-sub-title {
      font-size: 7vw;
      line-height: 5vw;
    }
  }
}

.tu-middle-section-wrapper {
h2 {
        font-size: 32px;
        line-height: 36px;
      }
.tu-worldcup-section {
    max-width: 400px;
    margin: 0 auto;
    width: 100%;
    margin-top: 21px;

    &::after,
    &:before {
      content: "";
      background: url('~/public/assets/images/ball-shadow-img.png') no-repeat;
      display: inline-block;
      position: absolute;
      width: 24vw;
      height: 21.8vw;
      top: 31px;
      left: -398px;
      background-size: 100%;
      max-width: 314px;
      @media (min-width: 1440px) and (max-width: 1920px) {
        top: calc(100% - 16vw);
        left: -25vw;
        }
  
      @media (max-width: 1150px) {
        left: -22vw;
      }
      @media (max-width: 767px) {
        left: -22vw;
        height: 164px;
        background-size: contain;
        width: 185px;
      }

      @media(max-width:480px){
        left: -8vw;
        height: 93px;
        background-size: contain;
        width: 161px;
        top: -21px;
      }
    }

    &:after {
      background-image: url('~/public/assets/images/bangla-world-cup-image.png');
      right: -385px;
      left: auto;
      width: 34vw;
      height: 28vw;
      max-width: 326px;
      top: 50px;
      @media (min-width: 1440px) and (max-width: 1920px) {
        right: -23vw;
        top: calc(100% - 16vw);
      }
      @media (max-width: 1199px) {
        right: -22vw;
      }

      @media (max-width: 991px) {
        top: 10%;
      }

      @media (max-width: 667px) {
     
        left: 80%;
      }
      @media (max-width:450px){
        top: 85px;
        width: 25vw;
        height: 20vw;
        max-width: 326px;
        left: 78%;
      }

    }

    &.tu-dailytoss-section {
     @media (max-width: 991px) {
        margin-top: 15vw;
        margin-right: 42vw;
      }

      &::after,
      &:before {
        top: auto;
        bottom: -23%;
      }


    }
  }
.tu-vote-section {
   
    padding: 50px 16px;
    @media (max-width:767.98px) {
      padding: 50px 16px 40px 16px;
    }
  .tu-content-wrapper .tu-country-select button{
    padding: 10px 15px;
  }
   span{
      svg{
      width: 41px;
      height: 22px;
      }
    }
    .tu-win-wrap {
      max-width: 1120px;
      margin: 0 auto;

      @media (max-width: 1440px) {
        max-width: 900px;
      }
    }
 .tu-content-wrapper {
    
      margin: 0 auto;
     max-width: 380px;
      text-align: center;
      margin-top: 86px;

      &.tu-first {
        h2 {
          margin-top: -6px;
        }

        .display-2 {
          font-size: 24px;
          line-height: 28px;
          font-weight: 500;
        }
      }
    h3 {
        font-size: 44px;
        font-weight: 500;
        line-height: 29px;
        color: $secondary;
      }

      .display-2 {
        font-size: 18px;
        line-height: 18px;

        @media (max-width: 767px) {
          font-size: 20px;
        }
      }

      .btn {
        font-size: 20px;
        line-height: 41px;
        font-weight: 400;
       position: relative;
       z-index: 1;
        background-color: $dark;
      max-width: 340px; 
        height: 65px;
        z-index: 1;
        position: relative;
      

        @media (max-width: 767px) {
          max-width: 210px;
          width: 100%;
          font-size: 13px;
          height: 45px;
          padding: 4px;
        }
      }

      .tu-country-select {
        display: block;
        width: 100%;
        margin: 0 auto;
        max-width: 340px;
        margin-bottom: 10px !important;
        @media (max-width: 767px) {
         ul{
          max-height: 130px;
          padding: 3px 7px;
         
          li{
            padding: 3px 7px;
            font-size: 1.2rem;
          }
         }
        }
        button {
          width: 100%;
          padding: 12px 15px;
          border: 2px solid #FFFFFF;
          border-radius: 10px;
          color: #ffffff;
          overflow-x: hidden;
          @media (max-width:767.76px){
            padding: 1px 4px;
          }

          span {
            font-size: 1.6rem;
            line-height: 32px;
            overflow: unset;
          }

          &::after {
            background-image: url('~/public/assets/images/dropdown.svg');
             position: absolute;
            right: 22px;
            border: none !important;
            height: 18px;
            width: 22px;
            background-size: contain;
            background-repeat: no-repeat;
            top: 29px;
            @media (max-width:767.76px){
            right: 16px;
            width: 18px;
            top: 19px;
            }
          }

          &[aria-expanded='true']::after {
            border-bottom: 13px solid #eef3f5;
            border-top: 0;
          }
        }

        ul {
          width: 100%;
          color: $dark;
        }
      }
    }
  }
}

.tu-mobile-img {
  display: none;
}

@media (max-width: 767px) {
  .tu-mobile-img {
    display: block;
  }

  .tu-desktop-img {
    display: none;
  }
}

.video-player-wrapper {
  position: relative;
  width: 100%;
  padding-top: 56.25%;
  

  .react-player {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
@media (max-width:767.98px){
  ul.slick-dots{
 bottom: 9px;
  }
  .tu-middle-section-wrapper {
    max-width: 210px;
    margin: 0 auto;
    h2{
      font-size: 1.8rem;
      line-height: 2.2rem;
    }
 .tu-worldcup-section:after{
  top: 22px;
  width: 90px;
  height: 100%;
  max-width: 326px;
  background-size: 122px;
  left: 94%;
 }
 .tu-worldcup-section::before{
  left: -26vw;
    height: 106px;
    background-size: contain;
    width: 161px;
    top: 11px;
 }
 .tu-country-select button{
  padding: 1px 4px;

 }

 .tu-vote-section .tu-content-wrapper .tu-country-select button span{
  font-size: 12px;
 }
  }

 .tu-content-wrapper{
  margin-top: 43px !important;
  h4{
    font-size: 9px;
line-height: 0;
  }
  h5{
    font-size: 1rem;
  }
 }

}
@media (max-width:360px) {
  .tu-middle-section-wrapper 
  .tu-worldcup-section:after{
    top: 58px;
    width: 26vw;
    height: 25vw;
    max-width: 326px;
    left: 87%;
    background-size: 100px;
   }
   
}
@media (max-width:576.98px) {
  .tu-worldcup-section {
  padding: 0;
  margin-top: 0 !important;
  }
}
.avatar-dropdown{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  img{
    width: 60px;
    height: 60px;
  }
  @media (max-width:576px) {
    grid-template-columns: 1fr 1fr 1fr;
    top: 160px !important;
    img{
      width: 40px;
      height: 40px;
    }
  }
}