.card {
  &-block {
    background-color: transparent;
    border: none;
    text-align: center;
    &-img-top {
      max-width: 20rem;
      margin-bottom: 2.2vw;
      position: relative;
      z-index: 2;
      width: 100%;
    }
    &-sm {
      .card {
        &-block {
          &-img-top {
            max-width: 14rem;
          }
        }
      }
    }
  }
}
@media (max-width: 1199.98px) {
  .card {
    &-block {
      &-sm {
        .btn {
          &-primary {
            font-size: 2.4vw;
          }
        }
      }
    }
  }
}

@media (max-width: 991.98px) {
  .card {
    &-block {
      &-sm {
        .card {
          &-block {
            &-img-top {
              max-width: 12vw;
            }
          }
        }
        .btn {
          &-primary {
            font-size: 2.4vw;
          }
        }
      }
    }
  }
}

@media (max-width: 767.67px) {
  .card {
    &-block {
      .btn {
        &-primary {
          font-size: 4vw;
          padding: 1vw 2.2vw;
        }
      }
      &-img-top {
        width: 80%;
        margin-bottom: 2.7rem;
      }
      &-sm {
        .card {
          &-block {
            &-img-top {
              max-width: 20vw;
            }
          }
        }
      }
    }
  }
}
