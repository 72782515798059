$primary: #e51e2b;

$light: #ffffff;

// Slick slider start here

.slick {
  &-dots {
    li {
      margin: 0;
      button {
        // width: 100%;
        // height: 100%;
        // padding: 0;
        &:before {
          transition: 0.3s;
          font-size: 12px;
          opacity: 1;
          color: $light;
        }
      }
    }
    li.slick-active {
      button {
        &:before {
          font-size: 18px;
          color: $primary;
        }
      }
    }
  }
}

.slider {
  &-nav {
    .slick-list {
      // margin: 0 -10px;
      padding-top: 14px !important;
      .block {
        padding: 0 10px;
      }
    }
  }
  &-img {
    width: 100%;
  }
}
@media (max-width: 575.98px) {
  .center-zoom {
    .owl-item {
      margin: 43px 0;
    }
  }
  .owl-carousel {
    .owl-item {
      .block-lock,
      .block-play {
        width: 30px;
      }
    }
  }

  .slider {
    &-nav {
      .slick-list {
        padding: 14px 20% 0 0 !important;
      }
    }
  }

  .center-arrow {
    .owl {
      &-prev,
      &-next {
        width: 3.5rem;
      }
    }
  }
}
